<template>
  <v-btn @click="handleClick" class="delete-btn" text>
    <span :class="{ 'delete-btn__content--reverse': reverse }" class="delete-btn__content">
      <span class="delete-btn__icon">
        <SvgIcon icon="delete-icon" />
      </span>
      <span
        v-if="$slots.default"
        :class="{ 'delete-btn__text--reverse': reverse }"
        class="delete-btn__text"
      >
        <slot></slot>
      </span>
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'DeleteBtn',
  props: {
    reverse: Boolean,
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-btn__content {
  display: flex;
  align-items: center;
  &--reverse {
    flex-direction: row-reverse;
  }
}
.delete-btn__icon,
.delete-btn__text {
  display: block;
}
.delete-btn__text {
  color: #ed1f24;
  margin: 0px 0px 0px 13px;
  &--reverse {
    margin: 0px 13px 0px 0px;
  }
}
</style>

<style lang="scss">
.delete-btn__icon {
  & > i {
    & > svg {
      & > path {
        fill: #ed1f24;
      }
    }
  }
}
</style>
